<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Switches from "vue-switches";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Permission Role",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Switches, Swal },
  data() {
    return {
      title: "Form Permission Role",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "Roles",
          href: "/role"
        },
        {
          text: "Permission Role",
          active: true
        }
      ],
      permissions: [],
      role: null,
      isError: false,
      errorMessage: null
    };
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail(){
      this.$axios.get(`/role/${this.$route.params.id}`)
        .then( (response) => { 
          const result = response.data.result
          this.role = result.role
          this.permissions = this.$store.state.permission.map(item => {
            return {
              permission: item,
              status: this.role.permissions.includes(item)
            }
          })
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
    },
    onSubmit(){
      let param = new FormData()
      this.permissions.forEach( item => {
        if(item.status === true){
          param.append('permissions', item.permission)
        }
      })
      this.$axios.post(`role/${this.$route.params.id}/permissions`, param)
        .then( () => {
          Swal.fire("Success", "Permission Role Updated Successfully", "success");
          this.$router.push(`/role`);
        })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
              dismissible
            >{{errorMessage}}</b-alert>
            <div id="permission-list">
              <h3 v-if="role !== null">Setting Permission to {{ role.name | capitalize }} Role</h3>
              <div class="table-responsive mb-0">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 5%">No</th>
                      <th style="width: 85%">Name</th>
                      <th style="width: 10%">Applicable</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(permission, index) in permissions" :key="index">
                      <th scope="row">{{ index+1 }}</th>
                      <td>{{ permission.permission.replace(/_/g, ' ') }}</td>
                      <td>
                        <switches v-model="permission.status" type-bold="false" color="warning" class="ml-1 mb-0"></switches>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button type="button" @click="onSubmit" class="btn btn-primary m-1 float-right">Submit Change</button>
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>